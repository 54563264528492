import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";

const BackToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 20) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);
    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  if (!isVisible) return null;

  return (
    <Button
      onClick={scrollToTop}
      style={{
        position: "fixed",
        bottom: "20px",
        right: "max(calc((100% - 1140px) / 2 + 20px), 20px)",
        zIndex: 1000,
        borderRadius: "50%",
        width: "50px",
        height: "50px",
        padding: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
      variant="primary"
    >
      <FontAwesomeIcon icon={faArrowUp} />
    </Button>
  );
};

export default BackToTopButton;
