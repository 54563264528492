import React from "react";
import NavBar from "../navbar";
import { Container } from "react-bootstrap";
import BackToTopButton from "../BackToTopButton";

const Layout = ({ children, currentPath }) => {
  return (
    <>
      <NavBar />
      <Container>{children}</Container>
      <BackToTopButton />
    </>
  );
};

export default Layout;
